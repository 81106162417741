<template>
  <div class="ma-2 ma-sm-5 ma-md-10">
    <v-sheet>
      <v-select label="Chart type" :items="chartTypes" v-model="chartType">
      </v-select>
      <v-select @change="data = prepChartData()" label="Choose a display unit" :items="units" v-model="selectedUnit">
      </v-select>
    </v-sheet>
    <v-sheet class="d-flex justify-center">
      <component v-bind:is="chartType" 
        v-if="data"
        ref="chartRef"
        :data="data"
        :options="options"
        :responsive="true"
        :maintainAspectRatio="false"
        :width="width/1.5" 
        :height="400"
        />
    </v-sheet>

    <v-sheet class="d-flex justify-center ma-5">
      <a :href="image64Url" target="_blank" :download="`${product.name}_chart.png`">
        Save Chart
      </a>
    </v-sheet>

    <v-divider/>

    <div v-if="parentProduct" class="my-2">
      <b>{{product.name}}</b> belongs to <a href="#" @click="changeProduct(parentProduct)">{{parentProduct.name }}</a>
    </div>
    <div v-if="product && product.hasChildren">
      <b>{{product.name }}</b> includes the following products:
      <template v-for="product in products">
        <div v-if="!product.hasChildren" class="ml-4 my-2" :key="product.id">
          <a href="#" @click="changeProduct(product)">{{product.name }}</a>
        </div>
      </template>
    </div>
    
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
const BarChart = () => import("@/components/charts/BarChart.vue");
const LineChart = () => import("@/components/charts/LineChart.vue");
import InventoryEventGroup from "@/services/InventoryEventGroup.js";

export default {
  components: { BarChart, LineChart },
  props: {
    product: {
      type: Object,
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    data: null,
    options: null,
    image64Url: null,
    width: 800,
    height: 600,
    products: [],
    // units: ["Cost", "Price", "Weight", "Unit", "Count"],
    units: ["All", "Cost", "Weight", "Count"],
    selectedUnit: 'Weight',
    chartType: 'BarChart',
    chartTypes: ['BarChart', 'LineChart'],
    parentProduct: null
  }),
  computed: {
    ...mapGetters("inventoryEvents", ["eventsService", "eventsDateRange"]),
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
  },
  mounted() {
    
    this.getAllLeafProducts(this.product, this.products);
    this.products.sort( (a, b) => a.name.localeCompare(b.name));

    this.getParentNode(this.product)
      .then((parent) => {
        this.parentProduct = parent;
      })
    
    this.initChart();
  },
  methods: {
    ...mapActions("products", ["getParentNode"]),
    initChart(){
      console.log('init chart module...')
      //destory if exist;
      if(this.$refs["chartRef"] && this.$refs["chartRef"].chartInstance){
        console.log("destorying old chart")
        this.$refs["chartRef"].chartInstance.destroy();
      }

      this.width = window.innerWidth;
      this.height = window.innerHeight;
      console.log(`width: ${this.width} : height: ${this.height}`)

      const wrapper = this;
      this.data = this.prepChartData();
      this.options = {
        onClick: wrapper.handleClick,
        animation: {
            onComplete: function() {
              wrapper.updateImage()
            }
        },
      };
    },
    handleClick(point, event) {
      console.log("clicked :", point, event);
      const item = event[0];
      if (item) {
        const dataIndex = item.index;
        const datasetIndex = item.datasetIndex;
        console.log(`clicked dataset [${datasetIndex}], data [${dataIndex}]`);
      }
    },
    updateImage() {
      const chartInstance = this.$refs["chartRef"].chartInstance;
      if(chartInstance){
        this.image64Url = chartInstance.toBase64Image();
      }
    },
    prepChartData() {
      console.log("prep chart data...")

      const labels = [];
      const costSet = { label: 'Cost', data: [], backgroundColor: 'rgba(0, 137, 123, .7)'}
      const weightSet = { label: 'Weight', data: [], backgroundColor: 'rgba(2, 119, 189, .7)'}
      const countSet = { label: 'Count', data: [], backgroundColor: 'rgba(142, 36, 170, .7)'}
      const adjustmentSet = { label: 'Adjustment', data: [], backgroundColor: 'rgba(216, 27, 96, .7)'}

      this.dates.forEach((date) => {
        labels.push(date.label);
        const summary = this.getData(this.product, date);
        costSet.data.push(summary.totalValue);
        weightSet.data.push(summary.totalQuantity);
        countSet.data.push(summary.count);
        adjustmentSet.data.push(summary.count);
      });

      const datasets = [];
      switch(this.selectedUnit){
        case 'Weight':
          datasets.push(weightSet);
          break;
        case 'Cost':
          datasets.push(costSet);
          break;
        case 'Adjustment':
          datasets.push(adjustmentSet);
          break;
        case 'Count':
          datasets.push(countSet);
          break;
        case 'All':
          datasets.push(countSet);
          datasets.push(costSet);
          datasets.push(weightSet);
          break;
      }

      return { labels, datasets };
    },
    getData(product, dateRange){
      const group = new InventoryEventGroup(
          product,
          dateRange.startTime,
          dateRange.endTime
        );
        const groupedEvents = this.eventsService.getGroupedEvents(group, this.costAdjustmentService);
        return {
          totalValue: groupedEvents.totalValue,
          totalQuantity: groupedEvents.totalQuantity,
          count: groupedEvents.size,
          hasAdjustment: groupedEvents.hasAdjustment,
        }
    },
    getAllLeafProducts(product, result){
      if(product.hasChildren){
        product.children.forEach((child) => {
          this.getAllLeafProducts(child, result);
        })
      }
      else{
        result.push(product);
      }
    },
    changeProduct(p){
      this.$emit("update:product", p);

    }
  },
};
</script>

<style></style>
